  <template>
  <div v-if="sliderBranding">
    <div>
      <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Slider settings</label>
      <div class="control" style="display:flex;align-items:center;">
        <label class="label" style="width:200px;margin-bottom:0;display:block;"
          >Turn on page</label
        >
        <v-switch style="padding: 0;margin:0" color="#6ab64a" hide-details v-model="landing.advanced.global.active_slider"></v-switch>
      </div>
    </div>
    <div v-if="landing.advanced.global.active_slider">
          <div>
              <v-tabs class="margin__tabs" v-model="activeLang">
                  <v-tab
                          v-for="(lang, i) in landing.selected_langs"
                          :key="i"
                          @change="changeLang(lang)"
                  >
                      <img :src="landing.advanced.langs.find(x => x.value === lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                      {{ lang }}
                  </v-tab>
                  <v-tab-item v-for="(lang, i) in landing.selected_langs" :key="i" style="padding-top:15px;">
                      <div
                              v-for="(slider, index) in sliderBranding[lang]"
                              :key="slider.id"
                              style="border-radius:10px;border:1px solid #ccc;padding:15px;margin-bottom:15px;"
                      >
                          <div
                                  style="display: flex;justify-content: space-between;align-items: center;"
                          >
                              <label class="label" style="margin:0;"
                              >Slider Group {{ +index + 1 }}</label
                              >
                              <div>
                                <v-btn v-if="slider.hide && !slider.settings" @click="showGroup(slider)" icon color="rgba(0, 0, 0, 0.54)" dark  small>
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn v-if="!slider.hide && !slider.settings" @click="hideGroup(slider)" icon color="rgba(0, 0, 0, 0.54)" dark  small>
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-menu offset-y open-on-hover bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon color="rgba(0, 0, 0, 0.54)" dark  small v-bind="attrs" v-on="on">
                                      <v-icon>mdi-cog</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item>
                                      <v-list-item-title
                                          style="cursor: pointer" @click="show_settings(slider)"
                                      > {{slider.settings?'Content':'Style'}} settings</v-list-item-title
                                      >
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-title
                                          style="cursor: pointer" @click="deleteSliderGroup(index)"
                                      > Delete </v-list-item-title
                                      >
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                          </div>
                          <div
                            v-if="!slider.hide && !slider.settings"
                            style="margin-bottom: 15px;"
                          >
                            <div style="margin-bottom:15px;">
                              <label class="label" style="display:block;margin-bottom:15px;">Group title</label>
                              <Editor
                                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                                  :init="options"
                                  v-model="slider.title"
                                  @onChange="changeSlider"
                              >
                              </Editor>
                            </div>
                              <div style="margin-bottom: 15px;">
                                  <div
                                          class="control"
                                          style="display:flex;align-items:center;margin-bottom:15px;"
                                  >
                                      <label
                                              class="label"
                                              style="width:200px;display:block;margin-bottom:0;"
                                      >Show view all link</label
                                      >
                                      <v-switch color="#6ab64a"
                                          hide-details
                                                style="padding: 0;margin: 0"
                                              v-model="slider.slider_view_all.show"
                                              @change="changeSlider"
                                      ></v-switch>
                                  </div>
                                  <div v-if="slider.slider_view_all.show" style="display:flex;align-items:center;">
                                    <div
                                        class="control"
                                        style="display:flex;align-items:center;flex:1;margin-right:15px;"
                                    >
                                      <label class="label" style="display:block;width:200px;margin-bottom:0">Button Name</label>
                                      <v-text-field
                                          outlined
                                          hide-details
                                          dense
                                          v-model="slider.slider_view_all.text"
                                          @input="changeSlider"
                                      ></v-text-field>
                                    </div>
                                    <div
                                        class="control"
                                        style="display:flex;align-items:center;flex:1;"
                                    >
                                      <label class="label" style="display:block;width:200px;margin-bottom:0">Button Link</label>
                                      <v-text-field
                                          outlined
                                          hide-details
                                          dense
                                          v-model="slider.slider_view_all.link"
                                          @input="changeSlider"
                                      ></v-text-field>
                                    </div>
                                  </div>
                              </div>
                              <label class="label" style="margin-top: 15px"
                              >You can add slides</label
                              >
                              <v-tabs
                                      class="langs__tabs margin__tabs"

                              >
                                  <v-tab
                                          v-for="(slide, sliderIndex) in slider.slides"
                                          :key="sliderIndex+'_'+slider.id"
                                          :style="
                    `background:#fff;color:rgba(0,0,0,.54);border:0.5px solid #fff;`
                  "
                                          @click="changeSliderIndex(sliderIndex, index)"
                                  >
                                      {{ sliderIndex + 1 }}
                                  </v-tab>
                                <div style="display:flex;align-items:center;">
                                <v-btn small @click="addSlide(slider)" icon style="padding:1px;border:1px solid rgb(238,238,238);border-radius:50%;">
                                  <v-icon color="primary">mdi-plus</v-icon>
                                </v-btn>
                                </div>
                                  <v-tab-item
                                          v-for="(slide, sliderIndex) in slider.slides"
                                          :key="sliderIndex"
                                          @click="changeSliderIndex(sliderIndex, index)"
                                          style="position:relative;"
                                  >
                                      <label class="label" style="display:block;margin-bottom:15px;">Upload image</label>
                                    <v-btn small @click="deleteSlider(index,sliderIndex)" icon style="position:absolute;right:5px;top:5px;">
                                      <v-icon color="grey">mdi-delete</v-icon>
                                    </v-btn>
                                    <div @click="changeSliderIndex(sliderIndex, index)" style="margin-bottom:15px;">
                                      <vue-dropzone
                                              :id="`upload_${index}_${sliderIndex}`"
                                              :ref="`upload_${index}_${sliderIndex}`"
                                              :options="dropzoneOptions"
                                              :destroyDropzone="false"
                                              @vdropzone-drop="changeSliderIndex(sliderIndex, index)"
                                              @vdropzone-mounted="mountDropzone(index, sliderIndex)"
                                              @vdropzone-sending="customEvent"
                                              @vdropzone-removed-file="removeFile"
                                              @vdropzone-success="onSuccess"
                                      >
                                      </vue-dropzone>
                                    </div>
                                      <div class="control" style="margin-bottom: 15px;">
                                          <label class="label" style="display:block;margin-bottom:15px;">Write your slider text</label>
                                          <Editor
                                                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                                                  :init="options"
                                                  v-model="slide.text"
                                                  @onChange="changeSlider"
                                          >
                                          </Editor>
                                      </div>
                                      <div
                                              class="control"
                                              style="display:flex;margin-bottom:15px;"
                                      >
                                          <label
                                                  class="label"
                                                  style="display:block;margin-bottom:0;width:200px;"
                                          >Clickable block</label
                                          >
                                          <v-switch color="#6ab64a"
                                              hide-details
                                                    style="padding: 0;margin: 0"
                                                  v-model="slide.useLink"
                                                  @change="changeSlider"
                                          ></v-switch>
                                      </div>
                                      <div
                                              class="control"
                                              v-if="slide.useLink"
                                              style="margin-bottom: 15px;display: flex;align-items: center;"
                                      >
                                          <label class="label" style="display:block;margin-bottom:0;width:200px;">Link</label>
                                          <!--                <v-text-field dense outlined solo hide-details v-model="slide.link" @input="this.changeSlider"></v-text-field>-->
                                          <v-combobox
                                                  outlined
                                                  dense
                                                  hide-details
                                                  style="max-width:300px;"
                                                  placeholder="Link for slider"
                                                  :items="links"
                                                  v-model="slide.link"
                                                  @change="changeSlider"
                                          >
                                          </v-combobox>
                                      </div>
                                  </v-tab-item>
                              </v-tabs>
                          </div>
                          <div
                                  v-if="!slider.hide && slider.settings"
                                  style="margin-top:15px;"
                          >
                              <div style="display: flex;align-items: center;margin-bottom:15px">
                                  <label
                                          class="label"
                                          style="width:250px;margin-bottom: 0;"
                                  >
                                      Background
                                  </label>
                                  <v-menu
                                          top
                                          nudge-left="16"
                                          nudge-bottom="98"
                                          :close-on-content-click="false"
                                          v-model="show_slider_color"
                                  >
                                      <template v-slot:activator="{ on }">
                                          <div
                                                  class="colorpicker"
                                                  :style="`background-color:${slider.background}`"
                                                  v-on="on"
                                          />
                                      </template>
                                      <v-card>
                                          <v-card-text class="pa-0">
                                              <v-color-picker
                                                      v-model="slider.background"
                                                      @input="changeSlider"
                                                      flat
                                              />
                                          </v-card-text>
                                      </v-card>
                                  </v-menu>
                              </div>
                              <div style="display: flex;align-items: center;margin-bottom:15px">
                                  <label
                                          class="label"
                                          style="width:250px;margin-bottom: 0;"
                                  >
                                      Background Group
                                  </label>
                                  <v-menu
                                          top
                                          nudge-left="16"
                                          nudge-bottom="98"
                                          :close-on-content-click="false"
                                          v-model="show_slider_bg"
                                  >
                                      <template v-slot:activator="{ on }">
                                          <div
                                                  class="colorpicker"
                                                  :style="`background-color:${slider.bg_group}`"
                                                  v-on="on"
                                          />
                                      </template>
                                      <v-card>
                                          <v-card-text class="pa-0">
                                              <v-color-picker
                                                      v-model="slider.bg_group"
                                                      @input="changeSlider"
                                                      flat
                                              />
                                          </v-card-text>
                                      </v-card>
                                  </v-menu>
                              </div>
                              <div style="display: flex;align-items: center;margin-bottom:15px">
                                  <label
                                          class="label"
                                          style="width:250px;margin-bottom: 0;"
                                  >
                                      Border color
                                  </label>
                                  <v-menu
                                          top
                                          nudge-left="16"
                                          nudge-bottom="98"
                                          :close-on-content-click="false"
                                          v-model="show_slider_color_border"
                                  >
                                      <template v-slot:activator="{ on }">
                                          <div
                                                  class="colorpicker"
                                                  :style="`background-color:${slider.border_color}`"
                                                  v-on="on"
                                          />
                                      </template>
                                      <v-card>
                                          <v-card-text class="pa-0">
                                              <v-color-picker
                                                      v-model="slider.border_color"
                                                      @input="changeSlider"
                                                      flat
                                              />
                                          </v-card-text>
                                      </v-card>
                                  </v-menu>
                              </div>
                              <div style="display:flex;margin-bottom:15px;align-items:center;">
                                  <label class="label" style="width:250px;margin-bottom:0">Position</label>
                                  <v-radio-group
                                      hide-details
                                          v-model="slider.show_type"
                                          row
                                          @change="changePosition(slider.show_type)"
                                  >
                                      <v-radio label="Bottom" value="bottom"></v-radio>
                                      <v-radio label="Top" value="top"></v-radio>
                                  </v-radio-group>
                              </div>
                              <div style="display:flex;margin-bottom:15px;align-items:center;">
                                  <label class="label" style="width:250px;margin-bottom:0"
                                  >Image position</label
                                  >
                                  <v-radio-group hide-details v-model="slider.type" row @change="changeSlider">
                                      <v-radio label="Left" value="horizontal"></v-radio>
                                      <v-radio label="Top" value="vertical"></v-radio>
                                  </v-radio-group>
                              </div>
                            <div style="display:flex;margin-bottom:15px;align-items:center;">
                              <label class="label" style="min-width:250px;margin-bottom:0"
                              >Block
                                {{ slider.type === "horizontal" ? "width: " : "height: " }}
                                <b
                                >{{
                                    slider.type === "horizontal"
                                        ? slider.horizontal.width
                                        : slider.vertical.height
                                  }}
                                  px</b
                                ></label
                              >
                              <div class="slider" style="width:100%;">
                                <v-slider
                                    v-if="slider.type === 'horizontal'"
                                    hide-details
                                    :min="30"
                                    :max="500"
                                    v-model="slider.horizontal.width"
                                    @change="changeSlider"
                                ></v-slider>
                                <v-slider
                                    v-if="slider.type === 'vertical'"
                                    hide-details
                                    :min="30"
                                    :max="500"
                                    v-model="slider.vertical.height"
                                    @change="changeSlider"
                                ></v-slider>
                              </div>
                            </div>
                            <div style="display:flex;margin-bottom:15px;align-items:center;">
                              <label class="label" style="min-width:250px;margin-bottom:0"
                              >Block
                                {{ slider.type === "horizontal" ? "height: " : "width: " }}
                                <b
                                >{{
                                    slider.type === "horizontal"
                                        ? slider.horizontal.height
                                        : slider.vertical.width
                                  }}
                                  px</b
                                ></label
                              >
                              <div class="slider" style="width:100%;">
                                <v-slider
                                    v-if="slider.type === 'horizontal'"
                                    hide-details
                                    :min="30"
                                    :max="500"
                                    v-model="slider.horizontal.height"
                                    @change="changeSlider"
                                ></v-slider>
                                <v-slider
                                    v-if="slider.type === 'vertical'"
                                    hide-details
                                    :min="30"
                                    :max="500"
                                    v-model="slider.vertical.width"
                                    @change="changeSlider"
                                ></v-slider>
                              </div>
                            </div>
                            <div style="display:flex;margin-bottom:15px;align-items:center;">
                              <label class="label" style="min-width:250px;margin-bottom:0"
                              >Image
                                {{ slider.type === "horizontal" ? "width: " : "height: " }}
                                <b
                                >{{
                                    slider.type === "horizontal"
                                        ? slider.horizontal.img_width
                                        : slider.vertical.img_height
                                  }}
                                  px</b
                                ></label
                              >
                              <div class="slider" style="width:100%;">
                                <v-slider
                                    v-if="slider.type === 'horizontal'"
                                    hide-details
                                    :min="30"
                                    :max="500"
                                    v-model="slider.horizontal.img_width"
                                    @change="changeSlider"
                                ></v-slider>
                                <v-slider
                                    v-if="slider.type === 'vertical'"
                                    hide-details
                                    :min="30"
                                    :max="500"
                                    v-model="slider.vertical.img_height"
                                    @change="changeSlider"
                                ></v-slider>
                              </div>
                            </div>
                            <div style="display:flex;margin-bottom:15px;align-items:center;">
                              <label class="label" style="min-width:250px;margin-bottom:0">Border radius <b>{{ slider.radius }} px</b></label>
                              <div class="slider" style="width:100%;">
                                <v-slider
                                    hide-details
                                    :min="0"
                                    :max="50"
                                    v-model="slider.radius"
                                    @change="changeSlider"
                                ></v-slider>
                              </div>
                            </div>
                          </div>
                      </div>

                      <v-btn
                              class="object__submit btn btn_primary"
                              style="float:right;"
                              color="primary"
                              small
                              outlined
                              @click="addSliderGroup(lang)"
                      >
                          Add slider group
                      </v-btn>
                  </v-tab-item>
              </v-tabs>
          </div>
      </div>
    <PopupDelete
      ref="PopupDelete"
      title="Are you sure about delete slider group?"
      @confirm="deleteSliderGroupConfirm()"
    />
    <PopupCrop
        title="Change image before upload?"
        ref="PopupCrop"
        @confirm="openEditor"
        @save="saveImg"
        @close="closeCrop"
    />
  </div>
</template>

<script>

import Cropper from "cropperjs";
import "../../../../node_modules/cropperjs/dist/cropper.min.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {mapGetters, mapMutations, mapState} from "vuex";
import Compressor from "compressorjs";
export default {
  name: "Slider",
  data() {
    return {
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
      links: [],
      deleteIndex: 0,
      show_slider_bg: false,
      default_slider_group: {
        id: 0,
        title: "Example title",
        slider_view_all: {
          show: false,
          text: "View all",
          link: "",
        },
        hide: false,
        settings:false,
        show_type: "bottom",
        border_color: "#cccccc",
        type: "horizontal",
        bg_group:"#ffffff",
        vertical: {
          height: "220",
          width: "150",
          img_height: "150",
        },
        horizontal: {
          height: "150",
          width: "250",
          img_width: "100",
        },
        background: "#ffffff",
        slides: [
          {
            img: require("@/assets/none.png"),
            text: "Example first block",
            text_link: null,
            useLink: false,
            link: null,
          },
        ],
      },
      slider: "",
      show_slider_color: false,
      show_slider_color_border: false,
      dropzoneOptions: {
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFiles: 2,
        maxFilesize: 3,
        dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
        destroyDropzone: false,
        autoProcessQueue:false,
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compress(file);
          }
        },
      },
      sliderIndexTab: 0,
      sliderGroupIndex: 0,
      defaultSlide:
          {
            img: require("@/assets/none.png"),
            text: "Example block",
            text_link: null,
            useLink: false,
            link: null,
          },
    };
  },
  components: {
    PopupDelete:() => import('@/components/Popup/PopupDelete'),
    vueDropzone:() => import('vue2-dropzone'),
    PopupCrop:() => import("@/components/Popup/PopupCrop")
  },
  computed: {
    ...mapState(["selected_langs"]),
    ...mapState(["landing"]),
    ...mapState('branding',['sliderBranding']),
    ...mapGetters("landing", ["current_lang"]),
    branding() {
      return JSON.parse(JSON.stringify(this.landing.branding));
    },
    activeLang:{
      set:function(val){

      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
  },
  methods: {
    ...mapMutations('branding',['setSlider']),
    ...mapMutations('landing',['setLandingSlider']),
    openEditor(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ].slides[this.sliderIndexTab].img = "";
        this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone.removeAllFiles();
        this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone.addFile(newFile);
        this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone.processQueue();
        this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src = canvas.toDataURL(file.type);
        this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      let height,
          width = 0;
      let sliderClass = `.sliderHorizontal_${this.sliderGroupIndex}_${this.sliderIndexTab}`;
      if (
          this.sliderBranding[this.landing.current_lang][
              this.sliderGroupIndex
              ].type === "horizontal"
      ) {
        height = this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ]["horizontal"].height;
        width = this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ]["horizontal"].width;
      } else {
        height = this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ]["vertical"].height;
        width = this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ]["vertical"].width;
        sliderClass = `.sliderVertical_${this.sliderGroupIndex}_${this.sliderIndexTab}`;
      }
      this.sliderBranding[this.landing.current_lang][
          this.sliderGroupIndex
          ].slides[this.sliderIndexTab].img = 1;
      this.changeSlider();
      let cropper = new Cropper(image, {
        preview: sliderClass,
        aspectRatio: height / width,
      });
    },
    async saveImg(file){
      file.cropped = true;
      this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone.removeAllFiles();
      this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone.addFile(file);
      this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone.processQueue();
      this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    closeCrop(){
      this.$refs[`upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`][0].dropzone.removeAllFiles();
    },
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          if(this.$store.state.isMobile){
            this.saveImg(result)
          }else{
            this.$refs.PopupCrop.open(result)
          }
        },
        error(e) {
          console.log(e);
        }
      })
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    addSlide(slider){
      slider.slides.push(JSON.parse(JSON.stringify(this.defaultSlide)));
    },
    changeSlider(){
      this.setLandingSlider(JSON.parse(JSON.stringify(this.sliderBranding)));
    },
    mountDropzone(grpIndex, slideIndex) {
      if (
        this.sliderBranding[this.current_lang][grpIndex].slides[slideIndex].img
      ) {
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          ...this.sliderBranding[this.current_lang][grpIndex].slides[slideIndex],
        };
        if (
          !this.sliderBranding[this.current_lang][grpIndex].settings &&
          !this.sliderBranding[this.current_lang][grpIndex].hide
        ) {
          if (this.$refs[`upload_${grpIndex}_${slideIndex}`][0]) {
            this.$refs[`upload_${grpIndex}_${slideIndex}`][0].manuallyAddFile(
              mockFile,
              this.sliderBranding[this.current_lang][grpIndex].slides[
                slideIndex
              ].img
            );

            this.$refs[`upload_${grpIndex}_${slideIndex}`][0].dropzone[
              "previewsContainer"
            ].children[1].children[0].children[0].src = this.sliderBranding[
              this.current_lang
            ][grpIndex].slides[slideIndex].img;
            this.$refs[`upload_${grpIndex}_${slideIndex}`][0].dropzone[
              "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
            this.$refs[`upload_${grpIndex}_${slideIndex}`][0].dropzone[
              "previewsContainer"
            ].children[1].children[1].style.background = "none";
            this.$refs[`upload_${grpIndex}_${slideIndex}`][0].dropzone.emit(
              "complete",
              mockFile
            );
          }
        }
      }
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    deleteSliderGroupConfirm() {
      this.sliderBranding[this.landing.current_lang].splice(
        this.deleteIndex,
        1
      );
      this.changeSlider();
    },
    deleteSliderGroup(ind) {
      this.$refs.PopupDelete.open();
      this.deleteIndex = ind;
    },
    changePosition(data) {
      this.landing.advanced.global.slider_positioning = data;
      this.changeSlider();
    },
    changeSliderIndex(indSlider, indGroup) {
      this.sliderIndexTab = indSlider;
      this.sliderGroupIndex = indGroup;
    },
    show_settings(slider) {
      slider.settings = !slider.settings;
      if (!slider.settings) {
        this.$nextTick(() => {
          for (
            let i = 0;
            i < this.sliderBranding[this.current_lang].length;
            i++
          ) {
            if (this.sliderBranding[this.current_lang][i].slides[0].img) {
              let mockFile = {
                accepted: true,
                cropped: true,
                kind: "image",
                ...this.sliderBranding[this.current_lang][i].slides[0],
              };
              setTimeout(() => {
                if (
                  !this.sliderBranding[this.current_lang][i].settings &&
                  !this.sliderBranding[this.current_lang][i].hide
                ) {
                  if (
                    this.$refs[
                      `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                    ][0] &&
                    this.$refs[
                      `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                    ].dropzone.files.length === 0
                  ) {
                    this.$refs[
                      `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                    ][0].manuallyAddFile(
                      mockFile,
                      this.sliderBranding[this.current_lang][i].slides[0].img
                    );

                    this.$refs[
                      `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                    ][0].dropzone[
                      "previewsContainer"
                    ].children[1].children[0].children[0].src = this.sliderBranding[
                      this.current_lang
                    ][i].slides[0].img;
                    this.$refs[
                      `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                    ][0].dropzone[
                      "previewsContainer"
                    ].children[1].children[0].style.borderRadius = 0;
                    this.$refs[
                      `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                    ][0].dropzone[
                      "previewsContainer"
                    ].children[1].children[1].style.background = "none";
                    this.$refs[
                      `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                    ][0].dropzone.emit("complete", mockFile);
                  }
                }
              }, 500);
            }
          }
        });
      }
      this.changeSlider();
    },
    hideGroup(slider) {
      slider.hide = true;
      this.changeSlider();
    },
    showGroup(slider) {
      slider.hide = false;
      this.changeSlider();
    },
    addSliderGroup(lang) {
      if(this.sliderBranding[lang].length){
        this.default_slider_group.id =
            +this.sliderBranding[lang][this.sliderBranding[lang].length - 1].id +
            1;
        this.sliderBranding[lang].push(
            JSON.parse(JSON.stringify(this.default_slider_group))
        );
      }else{
        this.default_slider_group.id = 0;
        this.sliderBranding[lang].push(
            JSON.parse(JSON.stringify(this.default_slider_group))
        );
      }
      this.changeSlider();
    },
    async customEvent(_file, xhr, formData) {
      if(_file.cropped){
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
        );
        formData.append("lending_settings_id", this.landing.settings_id);
        formData.append("image", _file);
      }
    },
    onSuccess(file) {
      if(this.sliderBranding[this.current_lang][this.sliderGroupIndex].type === 'horizontal'){
        let sliderClass = `sliderHorizontal_${this.sliderGroupIndex}_${this.sliderIndexTab}`;
        if(document.getElementsByClassName(sliderClass).length){
          document.getElementsByClassName(sliderClass)[0].children[0].src = file.xhr.response;
        }
      }else{
        let sliderClass = `sliderVertical_${this.sliderGroupIndex}_${this.sliderIndexTab}`;
        if(document.getElementsByClassName(sliderClass).length){
          document.getElementsByClassName(sliderClass)[0].children[0].src = file.xhr.response;
        }
      }
      this.sliderBranding[this.current_lang][this.sliderGroupIndex].slides[
        this.sliderIndexTab
      ].img = file.xhr.response;
      this.changeSlider();
    },
    removeFile() {
      this.sliderBranding[this.current_lang][this.sliderGroupIndex].slides[
        this.sliderIndexTab
      ].img = null;
      this.changeSlider();
    },
    setCanvas() {
      this.$store.commit("landing/setBranding", this.branding, true);
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    deleteSlider(gr, ind) {
      this.sliderBranding[this.landing.current_lang][gr].slides.splice(ind,1);
      this.changeSlider()
    },
  },
  mounted() {
    for (let i = 0; i < this.landing.menu.list.length; i++) {
      let currentMenu = this.landing.menu.list[i];
      let startLink = `${process.env.VUE_APP_LINK}/${this.$route.params.id}/`;
      if (currentMenu.type === 1) {
        let link = startLink + "menu/" + currentMenu.page.split(" ").join("");
        this.links.push(link);
      } else if (currentMenu.type === 4) {
        let link = startLink + "menu/" + currentMenu.page.split(" ").join("");
        this.links.push(link);
        for (
          let ind = 0;
          ind <
          currentMenu.content[this.landing.current_lang][3].content.listing
            .length;
          ind++
        ) {
          for (
            let index = 0;
            index <
            currentMenu.content[this.landing.current_lang][3].content.listing[
              ind
            ].content.length;
            index++
          ) {
            let listingLink = `${startLink}menu/${currentMenu.page
              .split(" ")
              .join("")}/${ind + 1}/${index + 1}`;
            this.links.push(listingLink);
          }
        }
      } else if (currentMenu.type === 5) {
        let link =
          startLink + "submenu/" + currentMenu.page.split(" ").join("");
        this.links.push(link);
      } else if (currentMenu.type === 6) {
        let link =
          startLink +
          "submenu/" +
          currentMenu.page.split(" ").join("") +
          "/faq";
        this.links.push(link);
      } else if (currentMenu.type === 7) {
        let link =
          startLink +
          "submenu/" +
          currentMenu.page.split(" ").join("") +
          "/contact";
        this.links.push(link);
      } else if (currentMenu.type === 8) {
        let link =
          startLink +
          "submenu/" +
          currentMenu.page.split(" ").join("") +
          "/events";
        this.links.push(link);
      }
    }
    // let canvas = document.getElementsByClassName('canvas');
    // if(canvas[0]) {
    //   canvas[0].scrollTop = canvas[0].scrollHeight;
    // }
    const slider = document.getElementById('slider_preview');
    if(slider){
      slider.scrollIntoView(false);
    }
  },
};
</script>

<style lang="scss">
@import "../../../assets/custom_fonts/Geometria/stylesheet.css";
</style>
